<template>
    <div>
        <img class="example-headImg" src="../../assets/image/technicalService/04-1-1banner.png"/>
        <div class="head-explain">
            <div class="head-explain-title">数据湖仓技术</div>
            <div class="head-explain-content">致力于帮助企业构建数据湖，推动企业数字化转型</div>
        </div>
        <div class="container-1">
            <div class="box-mm" style="margin-top:6rem;width: 139rem;">
                <div class="box-content">
                    <img class="box-content-img" src="../../assets/image/technicalService/04-1-1小图.png"/>
                </div>
                <div class="box-content">
                    <div class="box-content-content" style="margin-top:14rem">
                        比孚科技作为Amazon高级咨询合作伙伴，致力于帮助企业构建数据湖，实现核心流程数字化、自助式商业智能（Business Intelligence)、
                        BI移动化等服务，推动企业数字化转型。
                    </div>
                </div>
            </div>
        </div>
        <div class="custon-title1">数据湖服务</div>
        <div class="data-lake-box">
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1接入数据.svg"/>
                <div class="lake-box1">
                    <div class="title1">接入数据源类型</div>
                    <div class="title2">结构化数据、非结构化数据、企业内部数据、企业外部数据、批次数据、实时数据等；<br/>
                        包含但不仅限于以下数据源：ERP(SAP/Oracle ERP)、CRM(Salesforce/Microsoft Dynamics 365)、APP(Weixin/TalkingData)、图像语音。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1接入方式.svg"/>
                <div class="lake-box1">
                    <div class="title1">接入方式</div>
                    <div class="title2">可通过互联网公开网络、虚拟专用网络(VPN)、Amazon直连专线(Direct Connect)等方式完成云上和线下的网络连通。<br/>
                        使用数据库迁移服务（DMS）、Lambda、Kinesis、CLI等组件完成数据上云；使用Talend、Informatica、Kettle、Data Pipeline等第三方产品完成数据上云。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1批处理.svg"/>
                <div class="lake-box1">
                    <div class="title1">批处理</div>
                    <div class="title2">利用Redshift完成结构化数据的SQL处理；利用EMR Spark等完成结构化、非结构化的批数据处理；<br/>
                        利用EC2搭建第三方处理平台Airflow、MapR、Hadoop、Informatica等完成结构化、非结构化的批数据处理。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1实时处理.svg"/>
                <div class="lake-box1">
                    <div class="title1">实时处理</div>
                    <div class="title2">利用Kinesis、EMR Spark Streaming、Lambda、SQS等Amazon服务完成实时数据处理；<br/>
                        利用搭建在EC2的第三方处理平台Kafka、Rabbit MQ等完成实时数据处理。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1机器学习.svg"/>
                <div class="lake-box1">
                    <div class="title1">机器学习/人工智能</div>
                    <div class="title2">利用SageMaker、Rekognition、Lex等Amazon服务完成机器学习/人工智能；<br/>
                        利用搭建在EC2的第三方处理平台TensorFlow、Keras、MXNet、PyTorch等完成机器学习/人工智能。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1数据应用.svg"/>
                <div class="lake-box1">
                    <div class="title1">数据应用服务</div>
                    <div class="title2">使用QuickSight、Tableau、PowerBI、FineReport、FineBI等展示工具对接Athena、ElasticSearch、Redshift、EMR、Kylin等组件完成数据应用服务的提供。</div>
                </div>
            </div>
            <div class="card">
                <img src="../../assets/image/technicalService/04-1-1平台安全.svg"/>
                <div class="lake-box1">
                    <div class="title1">平台安全及监控</div>
                    <div class="title2">使用VPC、IAM、KMS、CloudTrial、CloudWatch等组件完成平台安全及监控。</div>
                </div>
            </div>
        </div>
        <div class="container-2" style="height: 86rem;">
            <div class="data-lake-headTitle" style="padding-top:6rem;">比孚大数据湖解决方案</div>
            <img class="custom-img1" src="../../assets/image/technicalService/04-1-1解决方案.png"/>
        </div>
        <div class="container-1">
            <div class="data-lake-headTitle" style="padding-top:6rem;">数据湖常用服务</div>
            <img class="custom-img2" src="../../assets/image/technicalService/04-1-1常用服务.png"/>
        </div>

        <div class="container-2" style="display:flex;height: 68rem;">
            <div class="custom-box2" >
                <div class="title1" >项目实施方法论</div>
                <div class="box2-1">
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-1-1Amazon服务.svg"/>
                        <div class="title2-1">Amazon服务</div>
                        <div class="title2-2">Amazon供应的集成服务套件可提供快速而轻松地构建和
                            管理数据湖以进行分析所需的每个要素。Amazon支持的数据湖可以采用传统数
                            据孤岛和数据仓库无法胜任的方法，处理组合不同类型的数据和分析方法以获
                            得更深层次的见解所必需的扩展性、敏捷性和灵活性。Amazon为客户提供最广
                            泛的分析阵列和机器学习服务，以轻松访问所有相关数据，而不会妨碍安全性
                            或监管。</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-1-1技术能力.svg"/>
                        <div class="title2-1">技术能力</div>
                        <div class="title2-2">比孚作为Amazon Partner Network(APN) 高级 (Adva
                            nced Tier) 咨询合作伙伴，致力以云技术为企业数字化转型赋能。拥有诸多
                            通过Amazon认证的技术人员，对Amazon服务的特点有着深刻的理解。</div>
                    </div>
                    <div class="card">
                        <img src="../../assets/image/technicalService/04-1-1行业经验.svg"/>
                        <div class="title2-1">丰富的行业经验</div>
                        <div class="title2-2">比孚团队成员来自多家国际知名咨询和信息技术服务公司
                            ，在管理与技术领域拥有丰富经验。通过给细分行业龙头企业的服务，不断积累
                            行业经验。框架化的业务成果积累，可以帮助团队成员快速了解行业。团队成员
                            整体丰富的行业经验，可以帮助客户快速找到技术和业务平衡点，帮助客户进行
                            业务基于数据湖上的落地。</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'dataWarehouse',
    metaInfo:{
        meta:[
        { name:'keywords', content:'数据湖仓技术,接入数据源类型,接入方式,批处理,实时处理,机器学习/人工智能,数据应用服务,平台安全及监控,Amazon服务,技术能力,丰富的行业经验' },
        { name:'description', content:'比孚科技作为Amazon高级咨询合作伙伴，致力于帮助企业构建数据湖，实现核心流程数字化、自助式商业智能（Business Intelligence)、 BI移动化等服务，推动企业数字化转型。' }
        ]
    },
    data() {
        return {
            checkIndex:1,
            activeIndex:'4'
        };
    },

    mounted() {
        this.activeIndex = '4'
        this.newsIndex = false
        this.refreshIndexInfo({
            data:this.activeIndex ,
            news:this.newsIndex
        });
        
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
        jumpRout(route,index){
            this.activeIndex = index
            localStorage.setItem('headId',index);
            this.$router.push(route)
        }
    },
};
</script>

<style lang="less" scoped>
.custom-head-list{
    width: 190rem;
    border-bottom: 2px solid #F8F8F8;
    height: 7.9rem;
    display: flex;
    .box{
        width: 139rem;
        margin: auto;
        display: flex;
    }
}
.custom-list-item{
    width: 20rem;
    height: 8rem;
    display: flex;
    text-align: center;
    align-items: center;
    .title{
        width: 100%;
        font-size: 1.8rem;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #4F4F4F;
        line-height: 2rem;
        text-align: center;
    }
}
.custom-list-ischeck{
    border-bottom: 2px solid #11A84F;
    color: #11A84F;
}
.data-lake-headTitle{
    margin: auto;
    width: 123.4rem;
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.data-lake-box{
    width: 139rem;
    margin: auto;
    margin-bottom: 6rem;
    .card{
        display: flex;
        height: 10rem;
        margin-top: 3rem;
        img{
            width: 10rem;
            height: 10rem;
        }
        .lake-box1{
            margin-left: 2rem;
            text-align: justify;
            .title1{
                height: 2.8rem;
                font-size: 2rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #333333;
                line-height: 2.8rem;
            }
            .title2{
                height: 4.8rem;
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #666666;
                line-height: 2.4rem;
                margin-top: 2rem;
            }
        }
    }
}
.custon-title1{
    width: 139rem;
    text-align: justify;
    margin:6rem 0 1.7rem  26.1rem;
    height: 2rem;
    font-size: 2rem;
    font-family: 'CN_Medium';
    font-weight: 500;
    color: #000000;
    line-height: 3rem;
}
.custom-img1{
    width: 123.4rem;
    height: 67.6rem;
    margin: auto;
    margin-top: 4.2rem;
}
.custom-img2{
    width: 104.5rem;
    height: 49.8rem;
    margin: auto;
    margin-top: 4.2rem;
    margin-bottom: 4.3rem
}
.custom-box1{
    margin-top:6rem;
    width: 139rem;
    margin: auto;
    .title1{
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        text-align: justify;
        margin-top: 6rem;
    }
    .title2{
        width: 139.5rem;
        height: 4.2rem;
        font-size: 1.6rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        text-align: justify;
        margin-top: 3.1rem;
    }
    .img1{
        width: 139.8rem;
        height: 7rem;
        margin-top: 4rem;
    }
    .box1{
        width: 139.8rem;
        display: flex;
        height: 15rem;
        margin-top: -7rem;
        .item-1{
            width: 15.4rem;
            height: 7rem;
            overflow: hidden;
        }
        .item-1:hover{
            height: 15rem;
            transition: all .3s;
        }
        .button-1{
            width: 5rem;
            height: 7rem;
            display: flex;
            margin: auto;
            cursor: pointer;
        }
        .content-1{
            width: 15.4rem;
            height: 7rem;
            background: #F9F9F9;
            margin-top: 1rem;
            .title{
                width: 13.4rem;
                height: 6.1rem;
                font-size: 1.6rem;
                font-family: 'CN_Normal';
                font-weight: 400;
                color: #666666;
                line-height: 2.4rem;
                text-align: justify;
                margin: auto;
                display: flex;
                align-items: center;
            }
        }
    }
    .box2{
        width: 139.8rem;
        // height: 45rem;
        display: flex;
        margin-top: 6rem;
        .box2-left{
            width: 32rem;
            .content-1{
                width: 32rem;
                height: 6rem;
                border: 1px solid #E6E6E6;
                .title1-1{
                    font-size: 1.6rem;
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #000000;
                    line-height: 2.4rem;
                    color: #11A84F;
                    text-align: justify;

                    margin: 1.8rem 0 0 3rem;
                }
            }
            .content-2{
                width: 32rem;
                height: 6rem;
                border: 1px solid #E6E6E6;
                cursor: pointer;
                .title1-1{
                    font-size: 1.6rem;
                    font-family: 'CN_Regular';
                    font-weight: 400;
                    color: #000000;
                    line-height: 2.4rem;
                    text-align: justify;

                    margin: 1.8rem 0 0 3rem;
                }
                .ischecked{
                    font-family: 'CN_Medium';
                    font-weight: 500;
                    color: #11A84F;
                }
            }
            .content-2:hover{
                background: #F9F9F9;
            }
        }
        .box2-right{
            width: 101.6rem;
            margin-left: 6rem;
            .content{
                width: 101.6rem;
                height: 12rem;
                font-size: 1.6rem;
                font-family: 'CN_Normal';
                font-weight: 400;
                color: #333333;
                line-height: 3rem;
                text-align: justify;
            }
            .box2-1{
                width: 101.6rem;
                height: 28rem;
                margin-bottom: 4.5rem;
                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-between;
                .card{
                    width: 24.6rem;
                    height: 20rem;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                    overflow: hidden;
                    img{
                        // margin: auto;
                        margin-top: 4rem;
                    }
                    .title2-1{
                        height: 2rem;
                        font-size: 2rem;
                        font-family: 'CN_Medium';
                        font-weight: 500;
                        color: #000000;
                        line-height: 3rem;

                        margin: 3rem 0 0 0;
                    }
                    .title2-2{
                        width: 20.4rem;
                        height: 0rem;
                        font-size: 1.6rem;
                        font-family: 'CN_Regular';
                        font-weight: 400;
                        color: #4F4F4F;
                        line-height: 3rem;
                        overflow: hidden;

                        margin: auto;
                        margin-top: 0.8rem;
                        text-align: justify;
                    }
                }
                .card:hover{
                    height: 28rem;
                    transition: all .5s;
                }
                .card:hover>.title2-2{
                    height:10.2rem;
                    transition: all .5s;
                }

            }
            .box2-2{
                width: 101.6rem;
                height: 36rem;
                margin-bottom: 6rem;

                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-between;
                .card{
                    width: 33rem;
                    height: 36rem;
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                    img{
                        // margin: auto;
                        margin-top: 4rem;
                    }
                    .title2-1{
                        height: 2rem;
                        font-size: 2rem;
                        font-family: 'CN_Medium';
                        font-weight: 500;
                        color: #000000;
                        line-height: 3rem;

                        margin: 3rem 0 0 0;
                    }
                    .title2-2{
                        width: 28.9rem;
                        font-size: 1.6rem;
                        font-family: 'CN_Regular';
                        font-weight: 400;
                        color: #4F4F4F;
                        line-height: 3rem;
                        overflow: hidden;

                        margin: auto;
                        margin-top: 1rem;
                        text-align: justify;
                    }
                }
            }
            .img2-3{
                width: 103.8rem;
                height: 23.7rem;
                margin-top: 3.5rem;
                margin-bottom: 4.8rem;
            }
            .img2-3-2{
                width: 101.6rem;
                height: 21.9rem;
                margin-top: 3.5rem;
                margin-bottom: 6rem;
            }
            .box2-3{
                width: 101.6rem;
                height: 30rem;
                margin-bottom: 6rem;
                margin-top: 4rem;

                /*设置当前元素为flex模式*/
                display: flex;
                /*行元素默认不折行，设置为折行*/
                flex-wrap: wrap;
                justify-content: space-around;
                .card{
                    width: 20rem;
                    height: 13rem;
                    background: #FFFFFF;
                    overflow: hidden;
                    img{
                        width: 10rem;
                        height: 10rem;
                        cursor: pointer;
                    }
                    .title3-1{
                        height: 2.4rem;
                        font-size: 1.6rem;
                        font-family: 'CN_Regular';
                        font-weight: 400;
                        color: #333333;
                        line-height: 2.4rem;
                        cursor: pointer;

                        margin-top: 1rem;
                    }
                    .message{
                        width: 20rem;
                        height: 14rem;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
                        display: flex;
                        align-items: center;

                        margin-top:0.5rem;
                        .title3-2{
                            width: 16.9rem;
                            height: 10.2rem;
                            font-size: 1.6rem;
                            font-family: 'CN_Regular';
                            font-weight: 400;
                            color: #4F4F4F;
                            line-height: 3rem;
                            text-align: justify;

                            margin: auto;
                        }
                    }
                }
                .card:hover{
                    height: 29.1rem;
                    transition: all .5s;
                }
            }
        }
    }
    .box3{
        width: 139.3rem;
        /*设置当前元素为flex模式*/
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;

        margin-top: -2rem;
        .card{
            width: 45.6rem;
            height: 26.7rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
            img{
                margin-top: 4rem;
                width: 6.4rem;
                height: 6.4rem;
            }
            .title3-1{
                height: 2rem;
                font-size: 2rem;
                font-family: 'CN_Medium';
                font-weight: 500;
                color: #000000;
                line-height: 3rem;

                margin-top: 3rem;
            }
            .title3-2{
                width: 39.6rem;
                height: 7.5rem;
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #4F4F4F;
                line-height: 3rem;
                text-align: justify;
                margin: auto;

                margin-top: 0.8rem;
            }
        }
    }
    .custom-more{
        width: 14rem;
        height: 4.6rem;
        border: 1px solid #11A84F;
        margin: auto;
        display: flex;
        text-align: center;
        align-items: center;
        cursor: pointer;

        margin-top: 4.5rem;
        span{
            width: 6.4rem;
            height: 3rem;
            font-size: 1.6rem;
            font-family: 'CN_Normal';
            font-weight: 400;
            color: #11A84F;
            line-height: 3rem;
            margin: auto;
        }
    }
}
.custom-box2{
    width: 139rem;
    margin: auto;
    .title1{
        height: 2rem;
        font-size: 2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 3rem;
        text-align: justify;
        margin-top: 6rem;
    }
    .box2-1{
        width: 138.8rem;
        height: 43rem;
        /*设置当前元素为flex模式*/
        display: flex;
        /*行元素默认不折行，设置为折行*/
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4.5rem;
        .card{
            width: 45.6rem;
            height: 26.7rem;
            background: #FFFFFF;
            box-shadow: 0px 0px 12px 0px rgba(233,233,233,0.6);
            overflow: hidden;
            img{
                // margin: auto;
                margin-top: 4rem;
                width:6.4rem;
                height: 6.4rem;
            }
            .title2-1{
                height: 2rem;
                font-size: 2rem;
                font-family: 'CN_Medium';
                font-weight: 500;
                color: #000000;
                line-height: 3rem;

                margin: 3rem 0 0 0;
            }
            .title2-2{
                width: 39.6rem;
                height: 6.5rem;
                font-size: 1.6rem;
                font-family: 'CN_Regular';
                font-weight: 400;
                color: #4F4F4F;
                line-height: 3rem;
                overflow: hidden;

                margin: auto;
                margin-top: 2rem;
                text-align: justify;
            }
        }
        .card:hover{
            height: 43rem;
            transition: all .5s;
        }
        .card:hover>.title2-2{
            height:25.6rem;
            transition: all .5s;
        }

    }
}
</style>
